import React from "react";
import icsuccess from "../components/assets/ic-success.gif";
import icsuccesss from "../components/assets/ic-successs.gif";
import Lottie from 'react-lottie';
import success from "../components/anime/anime.json"; 

const Success = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: success,
    color: "red",
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const returnToHome = () => {
  window.location.href = "/gateway";
}
  return (
    <section className="min-h-screen p-0 w">
      <div className="flex items-center justify-center min-h-screen">
      <div className=" mx-auto bg-white rounded-lg shadow-md p-6 flex flex-col gap-6 m-auto mt-36">
          <div className="text-center mb-6">
          <Lottie
                    options={defaultOptions}
                    height={100}
                    width={100}
                />
            <h4 className="text-xl px-2 font-bold mt-4">
            Click done to return to home page
            </h4>
          </div>
          <button onClick={() => returnToHome()} className="bg-[#161032] px-4 h-12 font-semibold md:text-lg  w-full w-100 flex items-center justify-center text-white py-3 rounded-lg mt-1 hover:bg-[red] transition ease-in-out duration-300" >Done</button>  
        </div>
      </div>
    </section>
  );
};

export default Success;
