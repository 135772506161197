// src/components/Comment.tsx
import React from 'react';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';

const Comment = ({ children, next }) => {
  return (
    <div className="relative bg-[#F7EDE2] text-[#4E5869] text-center p-5 rounded-lg shadow-lg">
      {/* <div className={`absolute ${next ? "-bottom-3" : "-top-1"} right-5 transform -translate-y-1/2 translate-x-1/2 w-0 h-0 border-t-0 border-r-8 border-b-8 border-l-8 border-transparent border-b-[#F7EDE2]`}></div> */}
      <div className={`absolute ${next ? "-bottom-5" : "-top-1"} right-5 transform -translate-y-1/2 translate-x-1/2 text-[#F7EDE2]`}>
        {
          !next ? <BiSolidUpArrow /> : <BiSolidDownArrow />
        }
      </div>
      {children}
    </div>
  );
};

export default Comment;
