import axios from "axios";
import { getAccessToken } from "./config";
import config from "../config.json";

const url =
  config.env !== "local"
    ? config.REACT_APP_BASE_URL
    : config.REACT_APP_TEST_URL;
// Create an Axios instance
const api = axios.create({
  baseURL: url,
  //baseURL: process.env.REACT_APP_TEST_URL
});

let accessToken = getAccessToken();
//let accessToken = ""
console.log("Access token", accessToken);

api.interceptors.request.use(
  async (config) => {
    if (accessToken) {
      config.headers["TransactionToken"] = `${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Method to call API endpoints

export default api;
