import { Fire } from "./Fire.js";
import api from "./apiCall.js";
import configData from "../config.json";

export const processApi = async (
  method,
  url,
  data = null,
  config = {},
  returnMessage = false
) => {
  const token = sessionStorage.getItem("accessToken");
  const headers = {
    Authorization: `${configData.apiSecret}`,
  };

  try {
    const response = await api({
      method,
      url,
      data,
      headers,
      ...config,
    });
    console.log("Global response", response);

    return returnMessage ? response.data : response.data?.data ?? true;
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error("Error response:", error.response);
      Fire("error", error.response.data.message);
      throw error.response.data;
    } else if (error.request) {
      // Request was made but no response was received
      console.error("Error request:", error.request);
      Fire("error", "Network error");
      throw new Error("No response received from server");
    } else {
      // Something else happened while setting up the request
      console.error("Error message:", error.message);
      Fire("error", "This was not suppsoed to happen, will get this right");
      throw new Error(error.message);
    }
  }
};
