import React, { useEffect, useState } from "react";
import Comment from "../components/common/comments";
import { AnimatePresence, motion } from "framer-motion";
import logo from "../components/assets/logo.svg";
import logoinfo from "../components/assets/ic-info.svg";
import cbn from "../components/assets/cbn.svg";
import icnaira from "../components/assets/ic-naira.svg";
import config from "../config.json";
import {
  initiateThirdPartyRide,
  initiateThirdPartyShop,
  postThirdpartyForm,
} from "../services/interface/InterfaceService";
import { Fire } from "../utils/Fire";
import { useLocation } from "react-router-dom";
import Success from "./Success";
import Decline from "./Decline";
import { formatAmount } from "../components/reusables/FormtMoney";



const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const Home = () => {
  // const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [openCommentid, setOpenCommentid] = useState(false);
  const [isOpenid, setIsOpenid] = useState(true);
  const [formData, setFormData] = useState({
    amount: "",
    emailAddress: ""
  });
  const [errorMessages, setErrorMessages] = useState({});
  const query = useQuery();
  const [status, setStatus] = useState("none");

  const validateForm = () => {
    let errors = {};

    if (!formData.amount) {
      errors.amount = "Amount is required.";
    } else if (isNaN(formData.amount.replace(/,/g, '')) || parseFloat(formData.amount.replace(/,/g, '')) <= 0) {
      errors.amount = "Amount must be a positive number.";
    }

    if (!formData.emailAddress) {
      errors.emailAddress = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailAddress)) {
      errors.emailAddress = "Email address is invalid.";
    }

    return errors;
  };

  useEffect(() => {
    setTimeout(() => {
      setOpenCommentid(true);
    }, 500);
    const refStatus = query.get('status');
    setStatus(refStatus);
  }, [query]);

  const handleOpenCloseIdinfo = () => {
    setIsOpenid(!isOpenid);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount") {
        let cleanedValue = value.replace(/[^0-9.]/g, '');
        const [integerPart, decimalPart] = cleanedValue.split(".");
        if (integerPart.length <= 9) {
          if(decimalPart){
            if(decimalPart.length >= 3)
              {
                return null
              }

            }     
          const formattedValue = formatAmount(cleanedValue);
          setFormData({
            ...formData,
            [name]: formattedValue
          });
        }
      } else {
        setFormData({
          ...formData,
          [name]: value
        });
      }
    };


  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      handlePayNow();
    } else {
      setErrorMessages(errors);
    }
  };

  const handlePayNow = async () => {
    try {
      const prefix = config.merchantPrefix;
      const reference = Math.random().toString(36).substring(5);
      const suffix = new Date().getTime();
      const fullRreference = `${prefix}${reference}${suffix}`;

      const request = {
        amount: formData.amount.replace(/,/g, ''),
        emailAddress: formData.emailAddress,
        identifier: fullRreference,
        redirectUrl: config.redirectUrl,
      };

      console.log(request)
      setLoading(true);
      let response = {};

      switch (config.appName) {
        case "shop":
          response = await initiateThirdPartyShop(request);
          break;
        case "insurance":
          response = await postThirdpartyForm(request);
          break;
        case "ride":
          response = await initiateThirdPartyRide(request);
          break;
        default:
          Fire("error", "Invalid Configuration, Please contact support");
          break;
      }
      console.log("response", response);
      if (response && response.redirectionUrl) {
        window.open(response.redirectionUrl, "_blank");
      } 
    } catch (error) {
      Fire(
        "error",
        "Unable to process your request at the moment, Please try again later"
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {status === "success" ? (<Success />) :
        status === "failed" ? (<Decline />) :
          <div className="flex min-h-screen items-center justify-center bg-transparent bg-gray-600">
            <div className="rounded-lg bg-white md:w-[700px] w-full  border-[1px] border-gray-200 shadow-sm px-5 py-5 relative pb-10 mx-auto text-center">
              <div className="flex flex-col pt-5 items-center text-center justify-center md:w-[75%] w-full md:mx-auto md:my-auto md:px-10">
                <div className="self-center mb-7">
                  <img src={logo} alt="logo" />
                </div>
                <div className=" flex md:w-[100%] w-full relative justify-center flex-row">
                  <div>
                    <h2 className=" text-xl font-bold mb-5">Pay in instalments in 3 steps</h2>
                  </div>
                </div>

                <div className=" flex md:w-[100%] w-full relative justify-center flex-row">
                  <AnimatePresence>
                    <span onClick={handleOpenCloseIdinfo} className="bg-[#F7EDE2] flex items-center justify-center py-2 rounded-full mb-5 hover-pointer gap-7 md:text-sm text-sm text-[#353535] font-medium w-full md:w-[75%]">
                      <p>Your journey will take a few steps</p>
                    </span>
                  </AnimatePresence>
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="w-[90%]  px-3 rounded-md py-2 mt-5 flex flex-col items-center"
                >
                  <div className="w-full">

                    <label
                      htmlFor="hs-leading-icon"
                      className="block text-left text-sm font-medium mb-1"
                    >
                      Amount
                    </label>
                    <div className="relative mb-5">
                      <input
                        type="text"
                        step="0.01"
                        name="amount"
                        value={formData.amount}
                        onChange={handleChange}
                        className="bg-gray-20 border-gray-300 h-12 py-2 px-8 outline-none  p-3 w-full border rounded-md"
                        placeholder="Enter amount"
                        required
                       
                      />

                      <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
                        <img src={icnaira} alt="in-naira" />


                      </div>
                      {errorMessages.amount && (
                        <p className="text-red-500 text-sm">
                          {errorMessages.amount}
                        </p>
                      )}
                    </div>

                  </div>
                  <div className="w-full">

                    <label
                      htmlFor="hs-leading-icon"
                      className="block text-left text-sm font-medium mb-1"
                    >
                      Email Address
                    </label>
                    <div className="relative mb-5">
                      <input
                        type="email"
                        name="emailAddress"
                        value={formData.emailAddress}
                        onChange={handleChange}
                        className="bg-gray-20 border-gray-300 h-12 py-2 px-8 outline-none p-3 w-full border rounded-md"
                        placeholder="Enter email address"
                        required
                      />




                      <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
                        <svg
                          className="shrink-0 size-4 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                        >
                          <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                          <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                        </svg>
                      </div>
                      {errorMessages.email && (
                        <p className="text-red-500 text-sm">{errorMessages.email}</p>
                      )}
                    </div>

                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-[#161032] h-12 font-semibold text-lg flex items-center justify-center text-white py-3 mt-5 rounded-lg hover:bg-[red] transition ease-in-out duration-300 w-full"
                  >
                    {loading ? "Connecting..." : "Pay now"}
                  </button>
                </form>
                <div className="text-center mt-12">
                  <p className="text-[#001F54] text-sm">Powered by</p>
                  <div className="flex items-center gap-1 mt-1">
                    <img className="w-4" src={logo} alt="cbn" />
                    <p className="text-[#001F54] text-sm font-semibold">
                      Premium<span className="text-red-500">Trust</span>Bank
                    </p>
                  </div>
                </div>

                <div className="flex w-full justify-center text-center items-center gap-2 mt-12 font-medium">
                  <img src={cbn} alt="cbn" />
                  <p className="text-[#001F54] md:text-md text-sm">
                    Licensed by the Central Bank of Nigeria
                  </p>
                </div>
              </div>
            </div>
          </div>
      }
    </>

  );
};

export default Home;
