// import { postThirdpartyForm } from "../services/interface/InterfaceService";

// Export functions to handle access tokens
export const setAccessToken = (token) => {
  localStorage.setItem("accessToken", token)
};

export const getAccessToken = () => {
  const token = localStorage.getItem("accessToken");
  return token;
};

// //starting point of call
// export const GetCustomerInformation = (data, category) => {

//    console.log("data", data, category)

//    const response = postThirdpartyForm(data, category)
//      if(response){
//        console.log("response", response)

//        // store the transaction reference in session storage to use thoughtout a session
//        const transactionRef = response.data.transactionReference;
//        sessionStorage.setItem("transRef", transactionRef);


//      }
// }

//To determine if we are following the full path or payment gateway
export const methodToCall = (path) => {
  localStorage.setItem("method", path)
}

export const getMethodToCall = () => {
  const pathToCall = localStorage.getItem("method");
  return pathToCall;
}

// export const setInitiateThirdPartyRequest = (data, category) => {
//   // Serialize the data and store it in session storage
//   sessionStorage.setItem('initiateThirdPartyRequestData', JSON.stringify(data));
//   sessionStorage.setItem('insuranceCategory', JSON.stringify(category));
// }

// const getInitiateThirdPartyRequest = () => {
//   // Retrieve the serialized data from session storage
//   const dataString = sessionStorage.getItem('initiateThirdPartyRequestData');
//   const categoryString = sessionStorage.getItem('insuranceCategory');
  
//   // Deserialize the data if it exists
//   const data = dataString ? JSON.parse(dataString) : null;
//   const category = categoryString ? JSON.parse(categoryString) : null;
  
//   return { data, category };
// }

