import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Fire = (messageType, message) => {

        if(messageType == "error"){
            toast.error(message)
        } else if (messageType == "success"){
            toast.success(message)
        } else if (messageType == "warning"){
            toast.warning(message)
        } else {
            toast.info(message)
        }
    }
