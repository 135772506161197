import React from "react";
import decline from "../components/assets/decline.gif";

const Decline = () => {

const returnToHome = () => {
  window.location.href = "/gateway";
}
  return (
    <section className="min-h-screen p-0">
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-full max-w-lg mx-auto bg-white rounded-lg shadow-md p-6">
          <div className="text-center mb-6">
            <img className="mx-auto w-1/2" src={decline} alt="Success Icon" />
            <h4 className="text-xl font-bold mt-4 mb-2">
              Your application was rejected due to some issues, you will be
              contacted for further information.
            </h4>
          </div>

          <div className="text-center">
            <h4 className="text-lg font-semibold mt-4 mb-2">
              Download Blayz Application
            </h4>
            <p className="px-2 mb-5 text-xsm text-muted text-center font-light">
              Thank you for submitting your information.
              <br />
              Ensure that you meet the requirement and try again. You can
              contact us for more information
            </p>
          </div>
          <button onClick={() => returnToHome()} className="bg-[#161032] px-4 h-12 font-semibold md:text-lg  w-full w-100 flex items-center justify-center text-white py-3 rounded-lg mt-1 hover:bg-[red] transition ease-in-out duration-300" >Done</button>  
        </div>
      </div>
    </section>
  );
};

export default Decline;
